import { Controller } from '@hotwired/stimulus';

export default class extends Controller {

  click(e) {
    const app = this;
    e.preventDefault();
    grecaptcha.ready(() => {
      grecaptcha.execute(e.target.dataset.key, { action: 'submit' }).then((token) => {
        let url = e.target.dataset.url.replaceAll('TOKEN', token);
        if (url.indexOf('DEAL_TYPE') > -1) {
          url = url.replaceAll('DEAL_TYPE', app.element.value);
        }

        fetch(url, {
          headers: { Accept: "text/vnd.turbo-stream.html" },
        }).then(r => r.text())
          .then(html => {
            Turbo.renderStreamMessage(html);
            document.body.classList.remove('failed-captcha'); 
          });
      });
    });
  }

}
