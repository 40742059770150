import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  fadeOut(e) {
    const target = this.findTarget(e, 'fadeOutTargetId');
    if (!target)
      return;
    target.style.opacity = 0.2
  }

  toggle(e) {
    e.preventDefault();
    const target = this.findTarget(e);
    if (!target)
      return;

    if (target.style.display != 'block') {
      target.style.display = 'block';
    } else {
      target.style.display = 'none';
    }
  }

  findTarget(e, targetIdName) {
    let targetId = e.target.dataset[targetIdName || 'targetId'];
    if (!targetId)
      return;

    if (targetId[0] != '#') {
      targetId = `#${targetId}`;
    }
    return document.querySelector(targetId);
  }

}
