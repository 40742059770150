import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  fetch(e) {
    e.preventDefault();

    let baseUrl = this.element.dataset.url;
    let query = `${this.element.name}=${this.element.value}`;
    let url = `${baseUrl}${baseUrl.includes('?') ? '&' : '?'}${query}`;

    fetch(url, {
        headers: { Accept: "text/vnd.turbo-stream.html" },
      }).then(r => r.text())
        .then(html => Turbo.renderStreamMessage(html));
  }
}
