import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    const app = this;
    window.addEventListener('load', () => {
      document.addEventListener('click', event => {
        if (event.target.classList.contains('js-modal')) {
          app.close();
        }
      });
    });
  }

  close() {
    const target = document.querySelector('.js-modal.open');
    if (target) {
      target.classList.remove('open');
      document.body.classList.remove('js-modal-open');
    }
  }

  click(e) {
    if (e.target.dataset.preventDefault) {
      e.preventDefault();
    }
    const target = document.getElementById(e.target.dataset.modalId || e.target.dataset.targetId);
    target.classList.add('open');
    document.body.classList.add('js-modal-open');
  }

}
